<template>
	<jw-frame v-if="show" logo="/title-logo.png" :home-page="firstPath" :menus="menus" title="聚有才" class="qyh-iframe" :current-menu-path="currentMenuPath" :tools="['top', 'breadcrumb']">
		<router-view />
		<div slot="top" style="float: right;">
			<el-menu mode="horizontal" text-color="#000" class="el-menu-demo home-top" @open="openMenu" @close="closeMenu">
				<el-submenu index="1" popper-class="el-user-out">
					<template slot="title">
						<span :class="{'employ-name': true, 'employ-no': isActive}">{{ employeeName }}{{ employeeNo ? `(${employeeNo})` : '' }}</span>
					</template>
					<el-menu-item index="1-1">
						<p class="select-item user-info" @click="goto('/user-setting/information')"><i class="mps-icon icon-gerenziliao login-out-close" /><span class="item-text">个人资料</span></p>
					</el-menu-item>
					<el-menu-item index="1-2">
						<p class="select-item user-info" @click="goto('/user-setting/password')"><i class="mps-icon icon-xiugaimima login-out-close" /><span class="item-text">密码修改</span></p>
					</el-menu-item>
					<el-menu-item index="1-3" class="login-out-wrap">
						<p class="select-item login-out" @click="logout"><span class="item-text">退出登录</span></p>
					</el-menu-item>
				</el-submenu>
			</el-menu>
		</div>
	</jw-frame>
</template>

<script>
import mainService from '@/services/main.js'
import axios from 'axios'
import Server from '@/apis'
import auto from '@/apis/permission'
export default {
  data () {
    return {
      currentMenuPath: window.location.hash.substring(1),
      show: true,
      employeeNo: 0,
      employeeName: '',
      isActive: false,
      firstPath: null,
      companyInfo: {
        sharesTypeStr: null,
        organName: null,
        oragnShortName: null,
        areaAddress: null,
        organPhone: null,
        organFax: null
      }
    }
  },
  computed: {
    menus: function () {
      const menus = mainService.getMenus(
        this.$router.options.routes[0].children,
        '',
        this.permission
      )
      window.sessionStorage.setItem('router', JSON.stringify(menus))
      return menus
    }
  },
  beforeCreate () {
    this.$router.beforeEach((to, from, next) => {
      this.currentMenuPath = to.path
      next()
    })
  },
  mounted () {
    this.createRouter()
  },
  methods: {
    async createRouter () {
      const partnerId = this._auth.userInfo.partnerId
      const employeeId = this._auth.userInfo.employeeId
      const res = await axios.get(`${Server.path.apiCrmOS}partner/${partnerId}/employee/${employeeId}/operations`, { params: { ownPort: 0 }})
      this.employeeNo = res.data.employeeNo
      this.employeeName = res.data.employeeName
      auto.setAuto(res.data.operations)
      this.show = true
      // 计算第一个路由
      var stack = mainService.getMenus(
        this.$router.options.routes[0].children,
        '',
        this.permission
      )
      var getFirstNode = function (node) {
        if (!node) {
          return []
        }
        const stack = []
        const queen = []
        let tmpNode = []
        stack.push(node)
        while (stack.length > 0) {
          tmpNode = stack.pop()
          queen.push(tmpNode)
          if (tmpNode.children && tmpNode.children.length > 0) {
            stack.push(tmpNode.children[0])
          }
        }
        return queen
      }
      const router = getFirstNode(stack[0])
      this.firstPath = '#' + router[router.length - 1].path
      if (this.$route.path === '/') {
        this.$router.push({ path: router[router.length - 1].path })
      }
      this.SyncCompanyInfo()
    },
    SyncCompanyInfo () {
      axios.get(`${Server.path.apiCrmOS}partner/${this._auth.userInfo.partnerId}/organ/${this._auth.userInfo.partnerId}`).then(({ data }) => {
        data.sharesTypeStr = ['全资', '控股', '参股'][data.sharesType]
        data.areaAddress = data.areaName && data.organAddress ? (data.areaName.split('/').join(',') + '' + data.organAddress) : ''
        this.companyInfo = data
      }).catch(({ response }) => {
        Server.$message(this, response)
      })
    },
    async logout () {
      await axios.get('/api/logout', { headers: { token: this._auth.getToken() }})
      this._auth.clearToken()
      window.sessionStorage.clear()
      this.$router.replace('/login')
    },
    goto (path) {
      this.$router.push({ path: path })
    },
    openMenu () {
      this.isActive = true
    },
    closeMenu () {
      this.isActive = false
    }
  }
}
</script>

<style lang="less">
    .button-company-info{
      color: #333;
      background: #fff;
      border: none;
      border-radius: 0;
      height: 46px;
      box-sizing: border-box;
      &:hover{
        border-bottom: 2px solid #409EFF;
      }
    }
    .company-infos{
      list-style: none;
      padding:0 10px;
      margin: 0;
      li{
        line-height: 30px;
        label{
          padding-right: 10px;
        }
      }
    }
  .home-top.el-menu-demo {
    .el-submenu {
      box-sizing: border-box;
      .text-tips{
        position: absolute;
        left: 74px;
        top: 12px;
        display: inline;
        font-weight: normal;
        font-style: normal;
        border-radius: 10px;
        background: #fc5d5b;
        line-height: 7px;
        padding: 4px;
        font-size: 6px;
        color: #fff;
      }
      .crm-font-text{
        padding:0 5px;
        font-size:18px;
        color:#333;
      }
      &.is-opened{
        border-bottom: 2px solid #409eff;
        color: #409eff !important;
        i,span{
          color: #409eff;
        }
      }
      .el-submenu__title {
        height: 49px;
        line-height: 49px;
        color: #000;
        .employ-name {
          vertical-align: top;
          &.employ-no{
            color: #0b9eff;
          }
        }
      }
    }
  }
  .el-user-out {
    left: auto !important;
    right: 25px;
    z-index: 10001 !important;
    .el-menu {
      min-width: 130px;
      .el-menu-item  {
        width: 160px;
        color: #000;
        padding: 0 20px;
        .login-out-close{
          margin-right: 10px;
        }
        &:hover {
          color: #0b9eff;
          .login-out-close {
            color: #0b9eff;
          }
        }
        &:hover .select-item{
          color: #66b1ff;
        }
        &:last-child{
          border-bottom: none;
        }
      }
      .login-out-wrap {
        border-top: 1px solid #DCDFE6;
        color: #606266;
        .login-out {
          margin-right: 10px;
          color: #606266;
        }
      }
    }
  }
  .qyh-iframe {
    .menu-noright {
      opacity: .6;
    }
    .company_name {
      padding-left: 20px;
      float: left;
      font-size: 14px;
      color: #000;
      height: 49px;
      line-height: 49px;
    }
    .xmf_font{
      margin-right: 5px;
    }
  }
</style>
