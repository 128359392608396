export default {
  /**
   * 根据路由配置获取菜单数据
   * @param {*} routers 路由配置
   * @param {*} root 根路径，如果没有就以 '/' 为根路径
   */
  getMenus (routers, root, permissionCb) {
    const menus = []
    routers.forEach(row => {
      let path = row.path
      if (path.startsWith('/')) {
        path = path.substring(1)
      }
      const aPath = (root || '') + '/' + path
      const menu = {
        id: row.meta.id, // 菜单 Id
        title: row.meta.title, // 菜单名称
        icon: row.meta.icon, // 菜单图标
        path: aPath, // 访问路径
        option: row.meta.option,
        component: row.component,
        promote: row.meta.promote,
        children: row.children && row.children.length > 0 ? this.getMenus(row.children, aPath, permissionCb) : [] // 子菜单
      }
      // if (permissionCb && (permissionCb(menu.id) || !menu.id)) {
      menus.push(menu)
      // }
    })
    return menus
  },
  /**
   * 判断菜单是否有权限
   * @param {String} menuid  菜单id
   */
  hasPermission (menuid) {
    if (!menuid) {
      return true
    } else {
      // TODO 调用后端接口或缓存 获取当前登录用户的权限信息，判断菜单权限
      return true
    }
  }
}
